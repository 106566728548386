<template>
  <Delete
    action-handler="expenses/delete"
    :payload-value="{ id: record.id, userId: record.userId }"
    :title="$t('title')"
    :description="$t('desc', { summary: record.summary })"
    :success-title="$t('deleted-title')"
    :success-description="$t('deleted-desc', { summary: record.summary })"
    @close="$emit('delete')"
  />
</template>

<i18n>
{
"en": {
"title": "Delete Expense?",
"desc": "Are you sure you want to delete <strong>{summary}</strong> ?",
"Cancel": "Cancel",
"delete": "Delete",
"deleted-title": "Expense is deleted",
"deleted-desc": "Expense <strong>{name}</strong> has been removed.",
"Ok": "Ok"
},
"ja": {
"title": "経費を削除?",
"desc": "本当に <strong>{summary}</strong> を削除しますか ?",
"Cancel": "取り消し",
"delete": "削除",
"deleted-title": "経費は削除",
"deleted-desc": "経費 <strong>{name}</strong> は削除",
"Ok": "Ok"
}
}
</i18n>

<script>
import Delete from '@/components/PopupDelete';

export default {
  name: 'DelExpense',
  components: {
    Delete,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  emits: ['delete'],
};
</script>

<style scoped></style>

<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Edit Expense') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <EditForm
          ref="form"
          :value="expense"
          :is-submitting="isSubmitting"
          :source-route="previousRoute"
          @submit="handleSubmit"
          @image-removed="handleImageRemoved"
        />
        <a-alert v-if="submitted" type="success">
          <template #message>
            <span v-html="$t('expense-updated', { name: expense.name })" />
            <router-link to="/expenses">
              {{ $t('View expenses') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Edit Expense": "Edit Expense",
    "expense-updated": "expense <strong>{name}</strong> updated,",
    "View expenses": "View expenses",
    "Update success": "Update success"
  },
  "ja": {
    "Edit Expense": "経費を編集",
    "expense-updated": "経費 <strong>{name}</strong> が更新されました。",
    "View expenses": "経費を見る",
    "Update success": "更新成功"
  }
}
</i18n>

<script>
import { getResponseError } from '@/utils/util';
import EditForm from '@/views/expenses/components/EditForm';

export default {
  name: 'Add',
  components: { EditForm },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      if (from.name) {
        vm.previousRoute = from.name;
      }
    });
  },
  data() {
    return {
      previousRoute: undefined,
      expense: undefined,
      isSubmitting: false,
      error: '',
      submitted: false,
      removedImage: [],
    };
  },
  watch: {
    $route(route) {
      if (route.name === 'expenses-edit') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      const params = { id: this.$route.params.id, userId: this.$route.query.userId };

      this.$store
        .dispatch('expenses/get', params)
        .then((data) => {
          this.expense = data;
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    async handleSubmit(data) {
      try {
        const { id } = this.expense;
        this.isSubmitting = true;
        this.submitted = false;
        this.expense = undefined;
        this.error = '';

        const { receipt, ...rest } = data;
        const updated = await this.$store.dispatch('expenses/update', { id, ...rest });

        if (receipt && typeof receipt !== 'string') {
          const receiptPayload = { id, file: receipt };

          await this.$store.dispatch('expenses/uploadReceipt', receiptPayload);
        }

        this.$message.success(this.$t('Update success'));
        this.expense = updated;

        this.isSubmitting = false;
        this.submitted = true;
        this.$refs.form.resetFields();

        this.goBack();
      } catch (err) {
        this.isSubmitting = false;
        this.error = getResponseError(err);
      }
    },
    handleImageRemoved(file) {
      this.removedImage.push(file);
    },
    goBack() {
      const listRoutes = ['expenses', 'reports-expenses'];

      if (listRoutes.includes(this.previousRoute)) {
        return this.$router.push({ name: this.previousRoute });
      }

      return this.$router.push({ name: 'expenses' });
    },
  },
};
</script>

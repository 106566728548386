<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Expenses') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column is-narrow">
            <SearchForm @filter="fetchData" />
          </div>
          <div v-if="selectedRows.length" class="column">
            <RequestApproval :value="selectedRows" @success="fetchData" />
          </div>
          <div v-permission="['add-expense']" class="column is-narrow" style="margin-left: auto">
            <router-link class="ant-btn ant-btn-lg ant-btn-primary" :to="{ name: 'expenses-add' }">
              {{ $t('Add Expenses') }}
            </router-link>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :row-selection="rowSelection"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #workDate="{ record }">
            {{ $filters.date(record.workDateStart, { format: $variables.dateFormat }) }}
          </template>
          <template #property="{ record }">
            {{ record.property.name }}
          </template>
          <template #cost="{ record }">
            <span v-if="record.cost && record.currency">
              {{ $filters.currency(record.cost, { currency: record.currency }) }}
            </span>
            <span v-else>-</span>
          </template>
          <template #summary="{ record }">
            {{ $t(record.summary) }}
          </template>
          <template #status="{ record }">
            {{ $t(record.status) }}
          </template>
          <template #action="{ record }">
            <DelExpense :record="record" @delete="fetchData" />
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Expenses": "Expenses",
    "Add Expenses": "Add Expenses",
    "Working day": "Working day",
    "Property": "Property",
    "Cost": "Cost",
    "Summary": "Summary",
    "Status": "Status",
    "created": "created",
    "waiting approval": "waiting approval",
    "approved": "approved",
    "rejected": "rejected",
    "payment in process": "payment in process",
    "cleaning fee": "cleaning fee",
    "advance payment fee": "advance payment fee",
    "emergency assistant fee": "emergency assistant fee",
    "adjustment fee": "adjustment fee"
  },
  "ja": {
    "Expenses": "経費",
    "Add Expenses": "経費を追加",
    "Working day": "勤務日数",
    "Property": "物件",
    "Cost": "金額",
    "Summary": "摘要",
    "Status": "ステータス",
    "created": "新規",
    "waiting approval": "未承認",
    "approved": "承認済み",
    "rejected": "却下",
    "payment in process": "支払い処理中",
    "cleaning fee": "清掃代",
    "advance payment fee": "駆けつけ対応",
    "emergency assistant fee": "立替金精算",
    "adjustment fee": "調整金"
  }
}
</i18n>

<script>
import pagination from '@/components/mixins/pagination';
import SearchForm from '@/views/expenses/components/SearchForm';
import DelExpense from '@/views/expenses/components/DelExpense';
import RequestApproval from '@/views/expenses/components/RequestApproval';

export default {
  name: 'Expenses',
  components: {
    RequestApproval,
    DelExpense,
    SearchForm,
  },
  mixins: [pagination],
  data() {
    return {
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: this.$t('Working day'),
          dataIndex: 'workDate',
          slots: { customRender: 'workDate' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          key: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Cost'),
          dataIndex: 'cost',
          key: 'cost',
          slots: { customRender: 'cost' },
        },
        {
          title: this.$t('Summary'),
          dataIndex: 'summary',
          slots: { customRender: 'summary' },
        },
        {
          title: this.$t('Status'),
          key: 'status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: ' ',
          key: 'action',
          width: '100px',
          slots: { customRender: 'action' },
        },
      ],
      selectedRows: [],
    };
  },
  computed: {
    rowSelection() {
      // disable selection if HC
      const { roles } = this.$store.getters;
      if (roles.includes('HC')) {
        return null;
      }

      const selectedRowKeys = this.selectedRows ? this.selectedRows.map((row) => row.id) : [];

      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
        getCheckboxProps: (record) => ({
          ...record,
          disabled: record.status === 'approved',
        }),
      };
    },
    hasSelectRows() {
      return this.selectedRows.length > 0;
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'expenses') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      this.selectedRows = [];
      this.list = undefined;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('expenses/list', this.$route.query)
        .then((resp) => {
          const { list, pagination } = resp;

          this.list = list.map((p, index) => {
            p._id = index + 1;
            return p;
          });

          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$router.push({
            name: 'expenses-edit',
            params: { id: record.id },
            query: { userId: record.userId },
          });
        },
      };
    },
  },
};
</script>

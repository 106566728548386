<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <a-select
        v-model:value="status"
        :placeholder="$t('Status')"
        style="min-width: 200px"
        :size="size"
        @change="debounceQuery"
      >
        <a-select-option v-for="(v, key) in statusOptions" :key="key" :value="v">
          {{ $t(v) }}
        </a-select-option>
      </a-select>
    </div>
    <div class="column is-narrow">
      <a-range-picker
        v-model:value="range"
        :size="size"
        :format="dateFormat"
        @change="debounceQuery"
      >
        <a-input :value="rangeString" :placeholder="$t('date range')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div v-if="status || range.length" class="column">
      <a-button :size="size" style="min-width: 110px" type="default" @click="handleClear">
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Status": "Status",
    "created": "created",
    "waiting approval": "waiting approval",
    "approved": "approved",
    "rejected": "rejected",
    "payment in process": "payment in process",
    "date range": "date range",
    "Clear": "Clear"
  },
  "ja": {
    "Status": "ステータス",
    "created": "新規",
    "waiting approval": "未承認",
    "approved": "承認済み",
    "rejected": "却下",
    "payment in process": "支払い処理中",
    "date range": "日付範囲",
    "Clear": "クリア"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import { cleanObject } from '@/utils/util';
import { STATUS } from '@/config/expense';

export default {
  name: 'SearchForm',
  components: { CalendarFilled },
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      propertyId: undefined,
      range: [],
      status: undefined,
      statusOptions: STATUS,
    };
  },
  computed: {
    rangeString() {
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: this.dateFormat })} ~ ${this.$filters.date(
          this.range[1],
          { format: this.dateFormat },
        )}`
        : '';
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      const range = { dateFrom: undefined, dateTo: undefined };

      if (this.range.length) {
        range.dateFrom = this.$filters.date(this.range[0], { format: dateFormat });
        range.dateTo = this.$filters.date(this.range[1], { format: dateFormat });
      }

      return range;
    },
  },
  mounted() {
    this.propertyId = this.$route.query.propertyId || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo]
      : [];
    this.status = this.$route.query.status || undefined;
  },
  methods: {
    // eslint-disable-next-line func-names
    debounceQuery: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        propertyId: this.propertyId,
        ...this.formattedRange,
        status: this.status,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.propertyId = undefined;
      this.status = undefined;
      this.range = [];
      this.debounceQuery();
    },
  },
};
</script>

<style scoped></style>

<template>
  <a-button
    :loading="isSubmitting"
    :size="size"
    class="m-r-3x"
    style="min-width: 180px"
    type="primary"
    @click="handleSubmit"
  >
    {{ $t('Request Approval') }}
  </a-button>
</template>

<i18n>
{
"en": {
"Request Approval": "Request Approval"
},
"ja": {
"Request Approval": "リクエスト承認"
}
}
</i18n>

<script>
export default {
  name: 'RequestApproval',
  props: {
    value: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: 'large',
    },
  },
  emits: ['success'],
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    async handleSubmit() {
      this.isSubmitting = true;
      const ids = this.value.map((expense) => expense.id);

      await this.$store.dispatch('expenses/requestApproval', { ids });

      this.isSubmitting = false;
      this.$emit('success');
    },
  },
};
</script>

<style scoped></style>
